<template>
  <div class="register-container">
    <div class="register-box">
      <h1 class="register-title">注册新账号</h1>
      <form @submit.prevent="handleRegister" class="register-form">
        <div class="form-group">
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder=" "
            required
          />
          <label for="username">用户名</label>
        </div>
        <div class="form-group">
          <input
            type="text"
            id="job"
            v-model="job"
            placeholder=" "
            required
          />
          <label for="job">工作岗位</label>
        </div>
        <div class="form-group">
          <input
            type="tel"
            id="phone"
            v-model="phone"
            placeholder=" "
            required
          />
          <label for="phone">手机号</label>
        </div>
        <div class="form-group">
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder=" "
            required
          />
          <label for="password">密码</label>
        </div>
        <div class="form-group">
          <input
            type="password"
            id="confirmPassword"
            v-model="confirmPassword"
            placeholder=" "
            required
          />
          <label for="confirmPassword">确认密码</label>
        </div>
        <button type="submit" class="register-button">注册</button>
      </form>
      <div class="login-link">
        <span>已有账号？</span>
        <router-link to="/login" class="login-button">登录</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      job: '', // 添加工作岗位的数据绑定
      phone: '', // 添加手机号的数据绑定
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    handleRegister() {
      console.log('注册中...', this.username, this.job, this.phone, this.password);
      // 这里可以添加注册逻辑
    },
  },
};
</script>

<style scoped>
/* 全局样式重置 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* 注册容器 */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #2A2A72, #009FFD);
  color: white;
}

/* 注册框 */
.register-box {
  background: rgba(30, 30, 30, 0.8);
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 400px;
}

/* 标题 */
.register-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: #00ffff;
}

/* 表单 */
.register-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* 增加元素间距 */
}

/* 表单组 */
.form-group {
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 0.9rem; /* 底部增加空间 */
  border: 2px solid #00ffff;
  border-radius: 8px;
  background: transparent;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #00ff00;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label {
  top: -12px; /* 小幅上移 */
  left: 10px;
  font-size: 0.85rem; /* 小一号 */
  color: #00ff00;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 5px;
}

.form-group label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #00ffff;
  pointer-events: none;
  transition: all 0.3s ease;
}

/* 登录链接 */
.login-link {
  text-align: center;
  margin-top: 1.5rem;
}

.login-button {
  color: #00ffff;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.login-button:hover {
  color: #00ff00;
}

/* 注册按钮 */
.register-button {
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  background: #00ffff;
  color: black;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;
}

.register-button:hover {
  background: #00ff00;
  box-shadow: 0 5px 15px rgba(255, 255, 0, 0.5);
}
</style>
