<template>
  <header class="HeaderPage">
    <div class="header-content">
      <h1 class="logo">绵商行开发一组周例会</h1>
      <nav class="nav-links">
        <router-link to="/TaskTable" class="nav-link">日常任务登记</router-link>
        <router-link to="/about" class="nav-link">周例会展示页面</router-link>
        <router-link to="/contact" class="nav-link">成员信息</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderPage',
};
</script>

<style scoped>


/* 头部样式 */
.HeaderPage {
  background: linear-gradient(135deg, #2A2A72, #009FFD); /* 与登录页面完全一致的渐变背景 */
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* 确保头部在最上层 */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* 网站名称/Logo */
.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00ffff; /* 与登录页面标题颜色一致 */
  margin: 0;
}

/* 导航链接 */
.nav-links {
  display: flex;
  gap: 1.5rem; /* 链接之间的间距 */
}

.nav-link {
  color: #00ffff; /* 与登录页面链接颜色一致 */
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #00ff00; /* 鼠标悬停时的颜色 */
}

/* 响应式设计 */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    margin-top: 0.5rem;
  }
}
</style>
