import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';

// 引入你的路由配置
import router from './router'; // 假设你有一个 router.js 文件定义了路由


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(VueRouter);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router // 将 router 添加到实例中
}).$mount('#app');
