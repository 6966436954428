<template>
  <div class="container">
    <h1>上线版本问题登记</h1>
    <div class="card-container">
      <div v-for="(problem, index) in problems" :key="index" class="card">
        <div class="card-header">
          <span>问题 {{ index + 1 }}</span>
          <button @click="removeProblem(index)">删除</button>
        </div>
        <div class="card-body">
          <div class="field">
            <label for="index">序号</label>
            <input :id="'index-' + index" v-model="problem.index" type="text" placeholder="请输入序号" />
          </div>
          <div class="field">
            <label for="period">周期</label>
            <input :id="'period-' + index" v-model="problem.period" type="text" placeholder="请输入周期" />
          </div>
          <div class="field">
            <label for="system">所属系统</label>
            <input :id="'system-' + index" v-model="problem.system" type="text" placeholder="请输入所属系统" />
          </div>
          <div class="field">
            <label for="item">事项</label>
            <input :id="'item-' + index" v-model="problem.item" type="text" placeholder="请输入事项" />
          </div>
          <div class="field">
            <label for="reason">问题原因</label>
            <input :id="'reason-' + index" v-model="problem.reason" type="text" placeholder="请输入问题原因" />
          </div>
          <div class="field">
            <label for="evaluation">问题认定及扣分</label>
            <input :id="'evaluation-' + index" v-model="problem.evaluation" type="text" placeholder="请输入问题认定及扣分" />
          </div>
          <div class="field">
            <label for="handler">处理负责人</label>
            <input :id="'handler-' + index" v-model="problem.handler" type="text" placeholder="请输入处理负责人" />
          </div>
          <div class="field">
            <label for="followUp">后续处理情况</label>
            <input :id="'followUp-' + index" v-model="problem.followUp" type="text" placeholder="请输入后续处理情况" />
          </div>
          <div class="field">
            <label for="deduction">扣分情况</label>
            <input :id="'deduction-' + index" v-model="problem.deduction" type="text" placeholder="请输入扣分情况" />
          </div>
        </div>
      </div>
    </div>
    <button @click="addProblem">添加问题</button>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'VersionProblem',
  setup() {
    const problems = ref([
      {
        index: '',
        period: '',
        system: '',
        item: '',
        reason: '',
        evaluation: '',
        handler: '',
        followUp: '',
        deduction: '',
      },
    ]);

    const addProblem = () => {
      problems.value.push({
        index: '',
        period: '',
        system: '',
        item: '',
        reason: '',
        evaluation: '',
        handler: '',
        followUp: '',
        deduction: '',
      });
    };

    const removeProblem = (problemIndex) => {
      problems.value.splice(problemIndex, 1);
    };

    return {
      problems,
      addProblem,
      removeProblem,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field {
  display: flex;
  gap: 10px;
  align-items: center;
}

label {
  width: 120px;
  text-align: right;
}

input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
