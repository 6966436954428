<template>
  <div id="app">
    <HeaderPage /> <!-- 引入头部组件 -->
    <router-view></router-view> <!-- 路由视图 -->
  </div>
</template>

<script>
import HeaderPage from './components/HeaderPage.vue'; // 引入 Header 组件

export default {
  name: 'App',
  components: {
    HeaderPage, // 注册 Header 组件
  },
};
</script>

<style>
/* 全局样式重置 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* 确保内容不被头部遮挡 */
#app {
  padding-top: 60px; /* 头部高度 + 额外间距 */
}
</style>
