// src/api.js

import axios from 'axios';

// 设置基础URL，根据实际情况修改
axios.defaults.baseURL = 'https://www.qilegeguai.com';
// 设置基础URL，根据实际情况修改
// axios.defaults.baseURL = 'http://47.108.153.103:8081';
// 获取任务列表
export function fetchTasks() {
  return axios.get('/api/tasks/getList');
}

// 新增任务
export function addTask(task) {
  return axios.post('/api/tasks/add', task);
}

// 更新任务
export function updateTask(id, task) {
  return axios.put(`/api/tasks/update/${id}`, task);
}

// 删除任务
export function deleteTask(id) {
  return axios.delete(`/api/tasks/delete/${id}`);
}
