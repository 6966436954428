import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from './components/LoginPage.vue'; // 引入你的登录组件
import RegisterPage from './components/RegisterPage.vue'; // 引入你的注册组件


import TradePage from './pages/TradePage.vue'; // 引入你的注册组件
import VersionProblem from './pages/VersionProblem.vue'; // 引入你的注册组件
import TaskTable from './pages/TaskTable.vue'; // 引入你的注册组件
 
  
  


Vue.use(VueRouter);

const routes = [
  { path: '/', component: TradePage },
  { path: '/login', component: LoginPage },
  { path: '/TaskTable', component: TaskTable },
  { path: '/TradePage', component: TradePage },
   { path: '/VersionProblem', name: 'VersionProblem', component: VersionProblem },
  { path: '/register', component: RegisterPage }
];

const router = new VueRouter({
  mode: 'history', // 采用 HTML5 History 模式
  routes
});

export default router;
