<template>
  <div class="task-table-container">
    <h2 class="table-title">任务管理</h2>
    <el-button type="primary" @click="addTask" class="add-task-button">新增任务</el-button>
    <el-table :data="tasks" style="width: 100%" border>
      <el-table-column prop="id" label="任务编号" width="120"></el-table-column>
      <el-table-column prop="name" label="任务名称" width="180">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" :disabled="!scope.row.editing" size="small"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="任务描述">
        <template slot-scope="scope">
          <el-input v-model="scope.row.description" :disabled="!scope.row.editing" size="small"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="完成状态" width="120">
        <template slot-scope="scope">
          <el-select v-model="scope.row.status" :disabled="!scope.row.editing" size="small">
            <el-option label="未开始" value="0"></el-option>
            <el-option label="分析中" value="1"></el-option>
            <el-option label="测试中" value="2"></el-option>
            <el-option label="已完成" value="3"></el-option>
            <el-option label="暂停" value="4"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.editing" @click="editTask(scope.$index)" size="small" type="text">编辑</el-button>
          <el-button v-if="scope.row.editing" @click="saveTask(scope.$index)" size="small" type="text">保存</el-button>
          <el-button @click="deleteTask(scope.$index)" size="small" type="text" class="delete-button">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fetchTasks, addTask, updateTask, deleteTask } from '../api';

export default {
  name: 'TaskTable',
  data() {
    return {
      tasks: [],
      nextId: 100, // 初始任务编号
    };
  },
  created() {
    this.loadTasks();
  },
  methods: {
    loadTasks() {
      fetchTasks()
        .then(response => {
          this.tasks = response.data.map(task => ({
            ...task,
            editing: false,
          }));
        })
        .catch(error => {
          console.error('获取任务列表失败:', error);
          this.$message.error('获取任务列表失败');
        });
    },
    addTask() {
      const newTask = {
        id: this.nextId.toString().padStart(3, '0'), // 生成三位数的任务编号
        name: '',
        description: '',
        status: '未完成',
        editing: true,
        isNew: true, // 新增属性用于标记新任务
      };
      this.tasks.push(newTask);
      this.nextId++;
    },
    editTask(index) {
      this.$set(this.tasks[index], 'editing', true);
    },
    saveTask(index) {
      const task = this.tasks[index];
      if (task.isNew) {
        // 新增任务
        addTask(task)
          .then(response => {
            this.$set(this.tasks[index], 'editing', false);
            this.$set(this.tasks[index], 'id', response.data.id); // 更新任务ID
            this.$set(this.tasks[index], 'isNew', false); // 标记为已保存
            this.$message.success('任务新增成功');
          })
          .catch(error => {
            console.error('新增任务失败:', error);
            this.$message.error('新增任务失败');
          });
      } else {
        // 编辑任务
        updateTask(task.id, task)
          .then(response => {
            this.$set(this.tasks[index], 'editing', false);
            this.$set(this.tasks[index], 'name', response.data.name);
            this.$set(this.tasks[index], 'description', response.data.description);
            this.$set(this.tasks[index], 'status', response.data.status);
            this.$message.success('任务保存成功');
          })
          .catch(error => {
            console.error('保存任务失败:', error);
            this.$message.error('保存任务失败');
          });
      }
    },
    deleteTask(index) {
      const task = this.tasks[index];
      if (task.isNew) {
        // 如果是新任务且未保存，直接删除
        this.tasks.splice(index, 1);
      } else {
        // 如果是已保存的任务，先删除后端数据再删除本地数据
        deleteTask(task.id)
          .then(() => {
            this.tasks.splice(index, 1);
            this.$message.success('任务删除成功');
          })
          .catch(error => {
            console.error('删除任务失败:', error);
            this.$message.error('删除任务失败');
          });
      }
    },
  },
};
</script>

<style scoped>
.task-table-container {
  padding: 2rem;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.table-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.add-task-button {
  margin-bottom: 1rem;
}

.delete-button {
  color: #dc3545;
}
</style>
