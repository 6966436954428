<template>
  <div class="trade-page">
    <el-container>
      <el-aside width="200px" class="sidebar">
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-menu-item index="1">上线版本问题登记</el-menu-item>
          <el-menu-item index="2">生产事件情况</el-menu-item>
          <el-menu-item index="3">开发中的需求进度情况</el-menu-item>
          <el-menu-item index="4">其他重要事项</el-menu-item>
          <el-menu-item index="5">培训计划</el-menu-item>
         </el-menu>
      </el-aside>

      <el-container>
        <el-header>
          <h1>交易页面</h1>
        </el-header>
        <el-main>
          <p>这是交易页面的主要内容区，请在左侧选择相关操作。</p>
          <!-- 您可以根据需要添加更多内容 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'TradePage',
  methods: {
    handleSelect(index) {
      console.log('选中的菜单项:', index);
      // 可以根据选中的菜单项进行不同的操作，例如跳转页面
     if (index === '1') {
        this.$router.push({ name: 'VersionProblem' }); // 跳转到信息录入页面
      }
    
    },
  },
};
</script>

<style scoped>

.trade-page {
  padding: 20px;
}

/* 可以添加更多样式以美化页面 */
.sidebar {
  background-color: #f7f7f7;
}
</style>
