<template>
  <div class="login-container">
    <div class="login-box">
      <h1 class="login-title">欢迎登录</h1>
      <form @submit.prevent="handleLogin" class="login-form">
        <div class="form-group">
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder=" "
            required
          />
          <label for="username">用户名</label>
        </div>
        <div class="form-group">
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder=" "
            required
          />
          <label for="password">密码</label>
        </div>
        <button type="submit" class="login-button">登录</button>
      </form>
      <div class="register-link">
        <span>还没有账号？</span>
        <router-link to="/register" class="register-button">注册</router-link>
 
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    handleLogin() {
      console.log('登录中...', this.username, this.password);
        // 假设这是验证过程，成功后执行跳转
    // 通常，您需要调用API进行验证，在这里我们假设验证成功
    const isValid = true; // 这里可以替换为实际的验证逻辑

    if (isValid) {
      // 跳转到交易页面
      this.$router.push('/TradePage'); // 假设交易页面的路由是 /trade
    } else {
      // 处理登录失败的逻辑，比如弹出错误提示
      console.error('登录失败');
    }
    },
  },
};
</script>

<style scoped>
/* 全局样式重置 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* 登录容器 */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #2A2A72, #009FFD);
  color: white;
}

/* 登录框 */
.login-box {
  background: rgba(30, 30, 30, 0.8);
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  animation: glow 2s infinite alternate;
  width: 100%;
  max-width: 400px;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  }
  to {
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.9);
  }
}

/* 标题 */
.login-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: #00ffff;
}

/* 表单 */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* 增加元素间距 */
}

/* 表单组 */
.form-group {
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 0.9rem; /* 底部增加空间 */
  border: 2px solid #00ffff;
  border-radius: 8px;
  background: transparent;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #00ff00;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label {
  top: -12px; /* 小幅上移 */
  left: 10px;
  font-size: 0.85rem; /* 小一号 */
  color: #00ff00;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 5px;
}

.form-group label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #00ffff;
  pointer-events: none;
  transition: all 0.3s ease;
}

/* 注册链接 */
.register-link {
  text-align: center;
  margin-top: 1.5rem;
}

.register-button {
  color: #00ffff;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}

.register-button:hover {
  color: #00ff00;
}

/* 登录按钮 */
.login-button {
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  background: #00ffff;
  color: black;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  margin-top: 1rem;
}

.login-button:hover {
  background: #00ff00;
  box-shadow: 0 5px 15px rgba(255, 255, 0, 0.5);
}
</style>
